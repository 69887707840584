import {
  ETH_CURRENT_PRICE,
  FROM_ID,
  FROM_ID_TX_1,
  FROM_ID_TX_4,
  FROM_ID_TX_5,
  IS_USD_WITHDRAW_TX_5,
  TIMESTAMP_TX_5,
  TO_ID,
  TO_ID_TX_1,
  TO_ID_TX_4,
  TO_ID_TX_5,
  TRANSACTION_HASH_TX_5,
  TX_5_TRANSACTION_VALUE_3,
  TX_5_VALUE,
  TIMESTAMP_TX_1,
  TIMESTAMP_TX_4,
  TRANSACTION_VALUE_TX_4,
  FROM_ID_TX_7,
  TIMESTAMP_TX_7,
  TO_ID_TX_7,
  TRANSACTION_VALUE_TX_7,
  TRANSACTION_HASH_TX_7,
  TO_ID_TX_8,
  TRANSACTION_VALUE_TX_8,
  FROM_ID_TX_8,
  TIMESTAMP_TX_8,
  TRANSACTION_HASH_TX_8,
  FROM_ID_TX_9,
  TO_ID_TX_9,
  TRANSACTION_VALUE_TX_9,
  TIMESTAMP_TX_9,
  TRANSACTION_HASH_TX_9,
  FROM_ID_TX_10,
  TRANSACTION_VALUE_TX_10,
  TO_ID_TX_10,
  TIMESTAMP_TX_10,
  TRANSACTION_HASH_TX_10,
  TRANSACTION_HASH_TX_11,
  TIMESTAMP_TX_11,
  FROM_ID_TX_11,
  TO_ID_TX_11,
  TRANSACTION_VALUE_TX_11,
} from "../constants/CommonValue";
import { getEtherBaseValue, getTotalValue } from "../helpers/HelperFunction";

const commonAddressList = [
  {
    id: "0x1077a09343a50b02b091fccb92975115f2773b6dd633fa8c9d5782e18dd6fe71",
    transactionHash: {
      title:
        "0x1077a09343a50b02b091fccb92975115f2773b6dd633fa8c9d5782e18dd6fe71",
      link: "",
    },
    method: "Transfer",
    block: "19638896",

    age: "1712917259",
    from: {
      title: FROM_ID,
      link: "",
    },
    to: {
      title: TO_ID,
      link: "",
    },
    amount: "50.000304730385889094",
    txnFee: "0.00025124",
  },
  {
    id: "0xd984d45f7b548d090c599298177dd48cb6a5a33fcfe96a9ed553f41b4a28d096",
    transactionHash: {
      title:
        "0xd984d45f7b548d090c599298177dd48cb6a5a33fcfe96a9ed553f41b4a28d096",
      link: "",
    },
    method: "Transfer",
    block: "19636112",

    age: "1712883647",
    from: {
      title: FROM_ID,
      link: "",
    },
    to: {
      title: TO_ID,
      link: "",
    },
    amount: "20.0030",
    txnFee: "0.000364968319716",
  },
  {
    id: "0x6200d6d0aa24b609059455a26d743f40f8849b4bbd28b99f80acf410a0c749dc",
    transactionHash: {
      title:
        "0x6200d6d0aa24b609059455a26d743f40f8849b4bbd28b99f80acf410a0c749dc",
      link: "",
    },
    method: "Transfer",
    block: "19659485",

    age: "1713166823",
    from: {
      title: FROM_ID,
      link: "",
    },
    to: {
      title: TO_ID,
      link: "",
    },
    amount: "50.000328176004671956",
    txnFee: "00.000238176197742",
  },
];
const valueList = [
  "20.00307769780362393",
  "50.000304730385889094",
  "50.000328176004671956",
];
const addressTableDataTx1 = [
  {
    id: "0xd984d45f7b548d090c599298177dd48cb6a5a33fcfe96a9ed553f41b4a28d096",
    transactionHash: {
      title:
        "0xd984d45f7b548d090c599298177dd48cb6a5a33fcfe96a9ed553f41b4a28d096",
      link: "",
    },
    method: "Transfer",
    block: "19636112",

    age: TIMESTAMP_TX_1,
    from: {
      title: FROM_ID_TX_1,
      link: "",
    },
    to: {
      title: TO_ID_TX_1,
      link: "",
    },
    amount: "10.06419769",
    txnFee: "0.00025124194158",
  },
];
const addressTableDataTx4 = [
  {
    id: "0x27a6e8b39e534899eff71737ad5eb543dfc537466541bf4cbf4bbd665ab0ef1d",
    transactionHash: {
      title:
        "0x27a6e8b39e534899eff71737ad5eb543dfc537466541bf4cbf4bbd665ab0ef1d",
      link: "",
    },
    method: "Transfer",
    block: "20792456",
    age: "1712917259",
    from: {
      title: FROM_ID_TX_4,
      link: "",
    },
    to: {
      title: TO_ID_TX_4,
      link: "",
    },
    amount: TRANSACTION_VALUE_TX_4,
    txnFee: "0.00663716676585947",
  },
];

const addressTableDataTx5 = [
  {
    id: TRANSACTION_HASH_TX_5,
    transactionHash: {
      title: TRANSACTION_HASH_TX_5,
      link: "",
    },
    method: "Transfer",
    block: "20384835",
    age: TIMESTAMP_TX_5,
    from: {
      title: FROM_ID_TX_5,
      link: "",
    },
    to: {
      title: TO_ID_TX_5,
      link: "",
    },
    amount: TX_5_VALUE,
    txnFee: "0.00663716676585947",
  },
];

const addressTableDataTx7 = [
  {
    id: TRANSACTION_HASH_TX_7,
    transactionHash: {
      title: TRANSACTION_HASH_TX_7,
      link: "",
    },
    method: "Transfer",
    block: "20792456",
    age: TIMESTAMP_TX_7,
    from: {
      title: FROM_ID_TX_7,
      link: "",
    },
    to: {
      title: TO_ID_TX_7,
      link: "",
    },
    amount: TRANSACTION_VALUE_TX_7,
    txnFee: "0.00663716676585947",
  },
];
const addressTableDataTx8 = [
  {
    id: TRANSACTION_HASH_TX_8,
    transactionHash: {
      title: TRANSACTION_HASH_TX_8,
      link: "",
    },
    method: "Transfer",
    block: "20792456",
    age: TIMESTAMP_TX_8,
    from: {
      title: FROM_ID_TX_8,
      link: "",
    },
    to: {
      title: TO_ID_TX_8,
      link: "",
    },
    amount: TRANSACTION_VALUE_TX_8,
    txnFee: "0.00663716676585947",
  },
];
const addressTableDataTx9 = [
  {
    id: TRANSACTION_HASH_TX_9,
    transactionHash: {
      title: TRANSACTION_HASH_TX_9,
      link: "",
    },
    method: "Transfer",
    block: "20792456",
    age: TIMESTAMP_TX_9,
    from: {
      title: FROM_ID_TX_9,
      link: "",
    },
    to: {
      title: TO_ID_TX_9,
      link: "",
    },
    amount: TRANSACTION_VALUE_TX_9,
    txnFee: "0.00663716676585947",
  },
];
const addressTableDataTx10 = [
  {
    id: TRANSACTION_HASH_TX_10,
    transactionHash: {
      title: TRANSACTION_HASH_TX_10,
      link: "",
    },
    method: "Transfer",
    block: "20792456",
    age: TIMESTAMP_TX_10,
    from: {
      title: FROM_ID_TX_10,
      link: "",
    },
    to: {
      title: TO_ID_TX_10,
      link: "",
    },
    amount: TRANSACTION_VALUE_TX_10,
    txnFee: "0.00663716676585947",
  },
];
const addressTableDataTx11 = [
  {
    id: TRANSACTION_HASH_TX_11,
    transactionHash: {
      title: TRANSACTION_HASH_TX_11,
      link: "",
    },
    method: "Transfer",
    block: "20792456",
    age: TIMESTAMP_TX_11,
    from: {
      title: FROM_ID_TX_11,
      link: "",
    },
    to: {
      title: TO_ID_TX_11,
      link: "",
    },
    amount: TRANSACTION_VALUE_TX_11,
    txnFee: "0.00663716676585947",
  },
];
export const transactionTimeline = {
  latest: "1713166823",
  first: "1712883647",
};
export const transactionTimelineTx1 = {
  latest: TIMESTAMP_TX_1,
  first: TIMESTAMP_TX_1,
};
export const transactionTimelineTx4 = {
  latest: TIMESTAMP_TX_4,
  first: TIMESTAMP_TX_4,
};
export const transactionTimelineTx5 = {
  latest: TIMESTAMP_TX_5,
  first: TIMESTAMP_TX_5,
};
export const transactionTimelineTx7 = {
  latest: TIMESTAMP_TX_7,
  first: TIMESTAMP_TX_7,
};
export const transactionTimelineTx8 = {
  latest: TIMESTAMP_TX_8,
  first: TIMESTAMP_TX_8,
};

export const transactionTimelineTx9 = {
  latest: TIMESTAMP_TX_9,
  first: TIMESTAMP_TX_9,
};

export const transactionTimelineTx10 = {
  latest: TIMESTAMP_TX_10,
  first: TIMESTAMP_TX_10,
};
export const transactionTimelineTx11 = {
  latest: TIMESTAMP_TX_11,
  first: TIMESTAMP_TX_11,
};

export const addressData = [
  //ADDRESS-DATA-1-FROM
  {
    id: FROM_ID_TX_1,
    isFrom: true,
    overview: {
      balance: "0.0013289",
      value: {
        primary: "3.364150217",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "3.364150217",
        value2: "213 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx1 },
    },
    multichainInfo: {
      value: "$4,284,645.52",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx1,
  },
  //ADDRESS-DATA-1-TO
  {
    id: TO_ID_TX_1,
    isFrom: false,
    overview: {
      balance: "10.06419769",
      value: {
        primary: `${getEtherBaseValue(
          ETH_CURRENT_PRICE,
          getTotalValue(valueList)
        )}`,
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: `${getEtherBaseValue(
          ETH_CURRENT_PRICE,
          getTotalValue(valueList)
        )}`,
        value2: "213 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx1 },
    },
    multichainInfo: {
      value: "$555,696,428.55",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx1,
  },
  //ADDRESS-DATA-2-FROM
  {
    id: FROM_ID,
    isFrom: true,
    overview: {
      balance: "0.0013289",
      value: {
        primary: "3.364150217",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "3.364150217",
        value2: "213 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline,
    },
    multichainInfo: {
      value: "$4,638,483.94",
      addressFound: "8",
    },
    transactionTableData: commonAddressList,
  },
  //ADDRESS-DATA-2-TO
  {
    id: TO_ID,
    isFrom: false,
    overview: {
      balance: `${getTotalValue(valueList)}`,
      value: {
        primary: `${getEtherBaseValue(
          ETH_CURRENT_PRICE,
          getTotalValue(valueList)
        )}`,
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: `${getEtherBaseValue(
          ETH_CURRENT_PRICE,
          getTotalValue(valueList)
        )}`,
        value2: "401   Tokens",
      },
    },
    moreInfo: {
      transactionTimeline,
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "21",
    },
    transactionTableData: commonAddressList,
  },
  //ADDRESS-DATA-3-FROM
  {
    id: FROM_ID,
    isFrom: true,
    overview: {
      balance: "0.0013289",
      value: {
        primary: "3.364150217",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "3.364150217",
        value2: "213 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline,
    },
    multichainInfo: {
      value: "$4,376,317.98",
      addressFound: "8",
    },
    transactionTableData: commonAddressList,
  },
  //ADDRESS-DATA-3-TO
  {
    id: TO_ID,
    isFrom: false,
    overview: {
      balance: `${getTotalValue(valueList)}`,
      value: {
        primary: `${getEtherBaseValue(
          ETH_CURRENT_PRICE,
          getTotalValue(valueList)
        )}`,
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: `${getEtherBaseValue(
          ETH_CURRENT_PRICE,
          getTotalValue(valueList)
        )}`,
        value2: "401   Tokens",
      },
    },
    moreInfo: {
      transactionTimeline,
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "21",
    },
    transactionTableData: commonAddressList,
  },
  //ADDRESS-DATA-4-FROM
  {
    id: FROM_ID_TX_4,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx4 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx4,
  },
  //ADDRESS-DATA-4-TO
  {
    id: TO_ID_TX_4,
    isFrom: false,
    overview: {
      balance: TRANSACTION_VALUE_TX_4,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "",
        value2: "401 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx4 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx4,
  },
  //ADDRESS-DATA-5-FROM
  {
    id: FROM_ID_TX_5,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx5 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx5,
  },
  //ADDRESS-DATA-5-TO
  {
    id: TO_ID_TX_5,
    isFrom: false,
    isWitdrawUsd: IS_USD_WITHDRAW_TX_5,
    overview: {
      balance: TX_5_VALUE,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: IS_USD_WITHDRAW_TX_5 ? TX_5_TRANSACTION_VALUE_3 : "",
        value2: "1 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx5 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx5,
  },
  //ADDRESS-DATA-7-FROM
  {
    id: FROM_ID_TX_7,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx7 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx7,
  },
  //ADDRESS-DATA-7-TO
  {
    id: TO_ID_TX_7,
    isFrom: false,
    overview: {
      balance: TRANSACTION_VALUE_TX_7,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "",
        value2: "401 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx7 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx7,
  },
  //ADDRESS-DATA-8-FROM
  {
    id: FROM_ID_TX_8,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx8 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx8,
  },
  //ADDRESS-DATA-8-TO
  {
    id: TO_ID_TX_8,
    isFrom: false,
    overview: {
      balance: TRANSACTION_VALUE_TX_8,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "",
        value2: "401 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx9 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx9,
  },
  //ADDRESS-DATA-9-FROM
  {
    id: FROM_ID_TX_9,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx9 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx9,
  },
  //ADDRESS-DATA-9-TO
  {
    id: TO_ID_TX_9,
    isFrom: false,
    overview: {
      balance: TRANSACTION_VALUE_TX_9,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "",
        value2: "401 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx9 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx9,
  },

  //ADDRESS-DATA-10-FROM
  {
    id: FROM_ID_TX_10,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx10 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx10,
  },
  //ADDRESS-DATA-10-TO
  {
    id: TO_ID_TX_10,
    isFrom: false,
    overview: {
      balance: TRANSACTION_VALUE_TX_10,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "",
        value2: "401 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx10 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx10,
  },

  //ADDRESS-DATA-11-FROM
  {
    id: FROM_ID_TX_11,
    isFrom: true,
    overview: {
      balance: "0.001290507761759",
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "1,289,139.80",
        value2: "266 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx11 },
    },
    multichainInfo: {
      value: "$1,444,210.68",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx11,
  },
  //ADDRESS-DATA-11-TO
  {
    id: TO_ID_TX_11,
    isFrom: false,
    overview: {
      balance: TRANSACTION_VALUE_TX_11,
      value: {
        primary: "",
        secondary: ETH_CURRENT_PRICE,
      },
      token: {
        value1: "",
        value2: "401 Tokens",
      },
    },
    moreInfo: {
      transactionTimeline: { ...transactionTimelineTx11 },
    },
    multichainInfo: {
      value: "$573,022,664.98",
      addressFound: "1",
    },
    transactionTableData: addressTableDataTx11,
  },
];
