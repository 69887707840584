import React, { useState } from "react";
import { Card } from "reactstrap";
import CommonInfoBody from "../../components/CommonInfoBody";
import {
  faCircleCheck,
  faHourglassStart,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour, faCopy } from "@fortawesome/free-regular-svg-icons";
import RedirectionText from "../../components/RedirectionText";
import CommonHorizontalDivider from "../../components/CommonHorizontalDivider";
import CommonNavigateBody from "../../components/CommonNavigateBody";
import { getTimeDiff } from "../../helpers/HelperFunction";

const OverviewTab = ({ blockOverview }) => {
  const [statusDone, setStatusDone] = useState(true);
  const [showMoreData, setShowMoreData] = useState(false);
  return (
    <>
      <Card className="common-tab-body mb-3">
        <CommonInfoBody label="Block Height:">
          <div className="block-height">
            <span className="common-info-text">{blockOverview?.id}</span>
            <CommonNavigateBody />
          </div>
        </CommonInfoBody>
        <CommonInfoBody label="Status:">
          <span
            className={`common-info-text info-text-chip ${
              blockOverview?.statusDone ? "success-chip" : "other-chip"
            }`}
          >
            <FontAwesomeIcon
              icon={
                blockOverview?.statusDone ? faCircleCheck : faHourglassStart
              }
              width={10}
              height={10}
              color={blockOverview?.statusDone ? "#00a186" : "#ADB5BC"}
            />
            <span className="ps-1">
              {blockOverview?.statusDone ? "Finalized" : "Unfinalized"}
            </span>
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Timestamp:">
          <span className="common-info-text">
            <FontAwesomeIcon icon={faClockFour} />{" "}
            {getTimeDiff(blockOverview?.timestamp?.actualTimeStamp)} (
            {blockOverview?.timestamp?.timeAgo})
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Proposed On:">
          <span className="common-info-text">Block proposed on slot </span>
          <RedirectionText
            label={blockOverview?.proposedOn?.slot}
            outerNavigateLink=""
          />
          <span className="common-info-text">, epoch </span>
          <RedirectionText
            label={blockOverview?.proposedOn?.epoch}
            outerNavigateLink=""
          />
        </CommonInfoBody>
        <CommonInfoBody label="Transactions:">
          <RedirectionText
            label={blockOverview?.transcation?.count}
            outerNavigateLink=""
          />
          <span className="common-info-text"> and </span>
          <RedirectionText
            label={blockOverview?.transcation?.internalCount}
            outerNavigateLink=""
          />
          <span className="common-info-text"> in this block</span>
        </CommonInfoBody>
        <CommonInfoBody label="Withdrawals:">
          <RedirectionText
            label={blockOverview?.Withdrawals}
            outerNavigateLink=""
          />
          <span className="common-info-text"> in this block </span>
        </CommonInfoBody>
        <CommonHorizontalDivider className="block-divider" />
        <CommonInfoBody label="Fee Recipient:">
          <RedirectionText
            label={blockOverview?.feeRecipient?.name}
            outerNavigateLink=""
          />{" "}
          <FontAwesomeIcon
            icon={faCopy}
            width={14}
            height={14}
            color="#adb5bd"
          />
          <span className="common-info-text">
            {" "}
            in {blockOverview?.feeRecipient?.time} secs{" "}
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Block Reward:">
          <span className="common-info-text">{blockOverview?.blockReward}</span>
        </CommonInfoBody>
        <CommonInfoBody label="Total Difficulty:">
          <span className="common-info-text">
            {blockOverview?.totalDifficulty}
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Size:">
          <span className="common-info-text">{blockOverview?.size}</span>
        </CommonInfoBody>
        <CommonHorizontalDivider className="block-divider" />
        <CommonInfoBody label="Gas Used:">
          <span className="common-info-text">
            {blockOverview?.gasUsed?.value1}{" "}
          </span>
          <span className="common-secondary-text pe-2">
            ({blockOverview?.gasUsed?.value2}){" "}
          </span>
          <span className="common-success-text">
            {blockOverview?.gasUsed?.value3} Gas Target
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Gas Limit:">
          <span className="common-info-text">{blockOverview?.gasLimit}</span>
        </CommonInfoBody>
        <CommonInfoBody label="Base Fee Per Gas:">
          <span className="common-info-text">
            {blockOverview?.baseFee?.value1} ETH{" "}
          </span>
          <span className="common-secondary-text">
            ({blockOverview?.baseFee?.value2})
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Burnt Fees:">
          <span className="common-info-text">
            🔥 {blockOverview?.burntFees} ETH
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Extra Data:" customClassName="block-info-body">
          <span className="common-info-text">{blockOverview?.extraData}</span>
        </CommonInfoBody>
        <CommonInfoBody label="Ether Price:" customClassName="block-info-body">
          <span className="common-info-text">${blockOverview?.etherPrice} / ETH</span>
        </CommonInfoBody>
      </Card>
      <Card className="common-tab-body mb-3">
        {showMoreData && (
          <div className="more-data-body">
            <CommonInfoBody label="Hash:">
              <span className="common-info-text">
                {blockOverview?.moreDetails?.hash}
              </span>
            </CommonInfoBody>
            <CommonInfoBody label="Parent Hash:">
              <RedirectionText
                label={blockOverview?.moreDetails?.parentHash}
                outerNavigateLink=""
              />
            </CommonInfoBody>
            <CommonInfoBody label="StateRoot:">
              <span className="common-info-text">
                {blockOverview?.moreDetails?.stateRoot}
              </span>
            </CommonInfoBody>
            <CommonInfoBody label="WithdrawalsRoot:">
              <span className="common-info-text">
                {blockOverview?.moreDetails?.withdrawalsRoot}
              </span>
            </CommonInfoBody>
            <CommonInfoBody label="Nonce:">
              <span className="common-info-text">
                {" "}
                {blockOverview?.moreDetails?.nonce}
              </span>
            </CommonInfoBody>
            <CommonHorizontalDivider className="block-divider" />
          </div>
        )}
        <CommonInfoBody
          label="More Details:"
          customClassName="block-info-body"
          hideInfoIcon
        >
          <span
            className="common-link-text"
            onClick={() => setShowMoreData(!showMoreData)}
          >
            <FontAwesomeIcon
              icon={faPlus}
              width={14}
              height={14}
              color="#0784c3"
            />
            <span className="ps-1">
              Click to show {!showMoreData ? "more" : "less"}
            </span>
          </span>
        </CommonInfoBody>
      </Card>
    </>
  );
};

export default OverviewTab;
