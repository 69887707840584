export const ETH_CURRENT_PRICE = "3,312";

// FOR 1 to 3 transaction
export const FROM_ID = "0x3FCd8FBe2B98947a452Bc9bF18C7cCFE9efdC214";
export const TO_ID = "0x73eAc6d85E927890633531C399b7CdAA46F6afd7";
// FOR 1st transaction : DONE
export const FROM_ID_TX_1 = "0x3FCd8FBe2B98947a452Bc9bF18C7cCFE9efdC214";
export const TO_ID_TX_1 = "0xA8Ae901592e87B003F47c072589ef6834F36FF05";
export const TIMESTAMP_TX_1 = "1727278322";
export const TIME_AGO_TX_1 = "Sep-25-2024 3:32:02 PM UTC";

// FOR 4th transaction : DONE
export const FROM_ID_TX_4 = "0x0Af5fe66F56FAbbde7e9C0fB54898A64B325070C";
export const TO_ID_TX_4 = "0x67363867fD6A79387FB6f2e383e13AC43cfabd0e";
export const TIMESTAMP_TX_4 = "1727274722";
export const TIME_AGO_TX_4 = "Sep-25-2024 2:32:02 PM UTC";
export const TRANSACTION_VALUE_TX_4 = "55.8845000461";
export const TOTAL_VALUE_TX_4 = "$141,473.288402";

// FOR 5th transaction : DONE
export const TX_5_FROM_ID_VALUE_1_AND_2 =
  "0xB2B0BFE6eC9510C15AadE77c78EDf4C0ce662efF";
export const TX_5_FROM_ID_VALUE_3 =
  "0x7ccbb5e4aef8fa251b64a56c9420da00049b6966";
export const FROM_ID_TX_5 = TX_5_FROM_ID_VALUE_3;

export const TRANSACTION_HASH_TX_5_1_AND_2 =
  "0x001c608d90532b6990f70ec0e5755bca5821a79a6b3d620d240350ee8394db19";
export const TRANSACTION_HASH_TX_5_3 =
  "0x6a76a1195428601f8b03c4fde07e1c8f2510c5d47b06f8b2523af7901528a99f";
export const TRANSACTION_HASH_TX_5 = TRANSACTION_HASH_TX_5_3;

export const TX_5_VALUE_1 = "22,500";
export const TX_5_VALUE_2 = "5,067";
export const TX_5_VALUE_3 = "0";
export const TX_5_VALUE = TX_5_VALUE_3;
export const TX_5_TRANSACTION_VALUE_3 = "22,500";
export const TX_5_TRANSACTION_VALUE = TX_5_TRANSACTION_VALUE_3;
export const TX_5_TO_ID_1 = "0x574F0835E80485660fdAF4e125892C9B93bCbc7a";
export const TX_5_TO_ID_2 = "0xa952e4319a59cab69ec05e1448b62d4cfebaf07b";
export const TX_5_TO_ID_3 = "0x4ea2f26ef6ebe2a4829e08a61248d52628af2d93";
export const TO_ID_TX_5 = TX_5_TO_ID_3;
export const TX_5_TOTAL_VALUE_1 = "$56,959,425.00";
export const TX_5_TOTAL_VALUE_2 = "$12,827,262.51";
export const TX_5_TOTAL_VALUE_3 = "0";
export const TOTAL_VALUE_TX_5 = TX_5_TOTAL_VALUE_3;
export const TX_5_TIMESTAMP_1 = "1727018140";
export const TX_5_TIMESTAMP_2 = "1727023240";
export const TX_5_TIMESTAMP_3 = "1728750548";
export const TIMESTAMP_TX_5 = TX_5_TIMESTAMP_3;
export const TX_5_TIME_AGO_1 = "Sep-22-2024 03:15:40 PM UTC";
export const TX_5_TIME_AGO_2 = "Sep-22-2024 04:40:40 PM UTC";
export const TX_5_TIME_AGO_3 = "Oct-12-2024 16:29:08 PM UTC";
export const TIME_AGO_TX_5 = TX_5_TIME_AGO_3;
export const TX_5_IS_USD_WITHDRAW_1_AND_2 = false;
export const TX_5_IS_USD_WITHDRAW_3 = true;
export const IS_USD_WITHDRAW_TX_5 = TX_5_IS_USD_WITHDRAW_3;

//TRANSACTION-7
export const TRANSACTION_HASH_TX_7 =
  "0x959f3d21bcd80cdf749b6b0f6867f5cd7c994abfbb1a2d63c4b7c24a26f54cd9";
export const BLOCK_ID_TX_7 = "28636112";
export const TO_ID_TX_7 = "0x346608643d06e6a19a523e6e9b79c8d06d5e0abe";
export const FROM_ID_TX_7 = "0x5328be38c14983B0F8d97aADFF4492F044490F0b";
export const TIME_AGO_TX_7 = "Sep-30-2024 16:32:46 PM UTC";
export const TIMESTAMP_TX_7 = "1727713966";
export const TRANSACTION_VALUE_TX_7 = "173";
export const TOTAL_VALUE_TX_7 = "$453,260";

//TRANSACTION-8
export const TRANSACTION_HASH_TX_8 =
  "0x8e387a999f2ed6995f62570b608164968df971b8ea7aed9687bd6288ee50a721";
export const BLOCK_ID_TX_8 = "284012662";
export const TO_ID_TX_8 = "0x4b123DfF390ae78A16A2A849cD9e17E98bC1884d";
export const FROM_ID_TX_8 = "0x5328be38c14983B0F8d97aADFF4492F044490F0d";
export const TIME_AGO_TX_8 = "Oct-22-2024 12:40:14 PM UTC";
export const TIMESTAMP_TX_8 = "1729600814";
export const TRANSACTION_VALUE_TX_8 = "0.190130";
export const TOTAL_VALUE_TX_8 = "$498.1406";

//TRANSACTION-9
export const TRANSACTION_HASH_TX_9 =
  "0xd315884efe1f4afef03057d45a91037f35a76598f4047e64324cc51fa0966a1d";
export const BLOCK_ID_TX_9 = "284012663";
export const TO_ID_TX_9 = "0xfe533d54dce91057c9ab378caf0dbf7d4ca9617c";
export const FROM_ID_TX_9 = "0x82D90bBaE7Fd625F87503E1ABcf4DFD627A76C0a";
export const TIME_AGO_TX_9 = "Oct-29-2024 12:05:06 PM UTC";
export const TIMESTAMP_TX_9 = "1730203506";
export const TRANSACTION_VALUE_TX_9 = "10.1964825";
export const TOTAL_VALUE_TX_9 = "$26,001.030375";

//TRANSACTION-10
export const TRANSACTION_HASH_TX_10 =
  "0xed204fe8168db6b9981bb5577984372e9d1657d1e48ab78e84d3c84590e361fe";
export const BLOCK_ID_TX_10 = "284210664";
export const TO_ID_TX_10 = "0x38be933E7Bac10Ad7B068DA4f5181cAa98D5d32C";
export const FROM_ID_TX_10 = "0x9bB7f34976f1781D3d4782c8C7e10D3665A02257";
export const TIME_AGO_TX_10 = "Oct-30-2024 15:37:13 PM UTC";
export const TIMESTAMP_TX_10 = "1730302633";
export const TRANSACTION_VALUE_TX_10 = "50.000304730385889094";
export const TOTAL_VALUE_TX_10 = "$133,800.815459";

//TRANSACTION-11
export const TRANSACTION_HASH_TX_11 =
  "0x17f381b5c8deb694d39c018f684613bb30da7e7ccbc5d9797299f8c013cad1cc";
export const BLOCK_ID_TX_11 = "284220664";
export const TO_ID_TX_11 = "0x3a302Af78CEd20A8D574F13D4Da78f94dD6Bf743";
export const FROM_ID_TX_11 = "0x167c14708c89644ed1e3fEfD5901970b25c36FDB";
export const TIME_AGO_TX_11 = "Nov-21-2024 5:59:54 PM UTC";
export const TIMESTAMP_TX_11 = "1732211994";
export const TRANSACTION_VALUE_TX_11 = "1.41651817";
export const TOTAL_VALUE_TX_11 = "$4,691.50817904";