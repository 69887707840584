import React, { useState } from "react";
import {
  Card,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CommonInfoBody from "../../components/CommonInfoBody";
import {
  faCaretRight,
  faCircleCheck,
  faHourglassStart,
  faPlus,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockFour,
  faCopy,
  faFileLines,
} from "@fortawesome/free-regular-svg-icons";
import RedirectionText from "../../components/RedirectionText";
import CommonHorizontalDivider from "../../components/CommonHorizontalDivider";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import UsdImage from "../../assests/images/usd.webp";
import DummyImage from "../../assests/images/DummyImage.webp";
import StackImage from "../../assests/images/stack.webp";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  getCenterDotFormattedId,
  getTimeDiff,
} from "../../helpers/HelperFunction";
const TAB_LIST = [
  { id: 1, name: "All Transfers" },
  { id: 2, name: "Net Transfers" },
];

const TransactionOverviewTab = ({ overviewDetails }) => {
  const [statusDone, setStatusDone] = useState(true);
  const [showMoreData, setShowMoreData] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (id) => setActiveTab(id);
  return (
    <>
      <Card className="common-tab-body mb-3">
        <CommonInfoBody label="Transaction Hash:">
          <span className="common-info-text pe-1">
            {overviewDetails?.transactionHash}
          </span>
          <FontAwesomeIcon
            icon={faCopy}
            width={14}
            height={14}
            color="#adb5bd"
            onClick={() => {
              navigator.clipboard.writeText(overviewDetails?.transactionHash);
            }}
            style={{ cursor: "pointer" }}
          />
        </CommonInfoBody>

        <CommonInfoBody label="Status:">
          <span
            className={`common-info-text info-text-chip ${
              overviewDetails?.statusDone ? "success-chip" : "other-chip"
            }`}
          >
            <FontAwesomeIcon
              icon={
                overviewDetails?.statusDone ? faCircleCheck : faHourglassStart
              }
              width={12}
              height={12}
              color={overviewDetails?.statusDone ? "#00a186" : "#ADB5BC"}
            />
            <span className="ps-1">
              {overviewDetails?.statusDone ? "Success" : "Unfinalized"}
            </span>
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Block:">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              width={12}
              height={12}
              color={"#00a186"}
            />
            <RedirectionText
              label={overviewDetails?.block?.blockNumber}
              goToInnerNavigate={() =>
                navigate(`/block/${overviewDetails?.block?.blockNumber}`)
              }
              className="ps-1"
            />
            <span className="common-info-text info-text-chip other-chip ms-1 cu-line-height-1">
              <span className="ps-1">
                {overviewDetails?.block?.blockConfirmation} Block Confirmations
              </span>
            </span>
          </div>
        </CommonInfoBody>

        <CommonInfoBody label="Timestamp:">
          <span className="common-info-text">
            <FontAwesomeIcon icon={faClockFour} />{" "}
            {getTimeDiff(overviewDetails?.timestamp?.actualTimeStamp)} (
            {overviewDetails?.timestamp?.timeAgo})
          </span>{" "}
          <span className="common-secondary-text">
            | <FontAwesomeIcon icon={faStopwatch} width={11} height={11} />{" "}
            {overviewDetails?.timestamp?.confirmedWithin}
          </span>
        </CommonInfoBody>
        <CommonHorizontalDivider className="block-divider" />

        <CommonInfoBody
          label="Transaction Action:"
          customIcon={
            <span className="me-1">
              <FontAwesomeIcon
                icon={faBolt}
                width={11}
                height={11}
                color="#0784c3"
              />
            </span>
          }
        >
          <span className="d-flex align-items-center gap-1">
            <FontAwesomeIcon
              icon={faCaretRight}
              width={6}
              height={6}
              color="#adb5bd"
            />
            <div>
              <span className="common-secondary-text">
                {overviewDetails?.isWithdrawUsd ? "Withdraw" : "Transfer"}{" "}
              </span>
              <span className="common-info-text">
                {overviewDetails?.transactionAction?.value}{" "}
              </span>
              {overviewDetails?.isWithdrawUsd && (
                <span className="common-secondary-text">
                  (
                  {
                    overviewDetails?.transactionAction
                      ?.transactionCurrencySymbol
                  }
                  {overviewDetails?.transactionAction?.value}){" "}
                </span>
              )}
              {overviewDetails?.transactionAction?.price && (
                <span className="common-secondary-text">
                  ({overviewDetails?.transactionAction?.price}){" "}
                </span>
              )}
              {overviewDetails?.isWithdrawUsd && (
                <span className="link-warning-hover-body px-1">
                  <img src={UsdImage} alt="" width={15} height={15} />{" "}
                  <RedirectionText
                    label={
                      overviewDetails?.transactionAction?.transactionCurrency
                    }
                    outerNavigateLink={overviewDetails?.transactionAction?.link}
                  />
                </span>
              )}
              {!overviewDetails?.isWithdrawUsd && (
                <span className="common-info-text"> ETH </span>
              )}
              <span className="common-secondary-text">To </span>
              <span className="link-warning-hover-body px-1">
                {/* <img src={DummyImage} alt="" width={15} height={15} />{" "} */}
                <RedirectionText
                  label={
                    overviewDetails?.isWithdrawUsd
                      ? getCenterDotFormattedId(
                          overviewDetails?.transactionAction?.secondaryId
                        )
                      : overviewDetails?.transactionAction?.secondaryId
                  }
                  goToInnerNavigate={() =>
                    navigate(
                      `/address/${overviewDetails?.transactionAction?.secondaryId}`
                    )
                  }
                />
              </span>
              {overviewDetails?.isWithdrawUsd && (
                <span className="common-secondary-text">from </span>
              )}
              {/* <img src={StackImage} alt="" width={15} height={15} />{" "} */}
              {/* <span className="common-secondary-text">
                  {overviewDetails?.transactionAction?.primaryFromId}{" "}
                </span> */}
              {overviewDetails?.isWithdrawUsd && (
                <RedirectionText
                  label={getCenterDotFormattedId(
                    overviewDetails?.transactionAction?.primaryFromId
                  )}
                  goToInnerNavigate={() =>
                    navigate(
                      `/address/${overviewDetails?.transactionAction?.primaryFromId}`
                    )
                  }
                />
              )}
            </div>
          </span>
        </CommonInfoBody>
        <CommonHorizontalDivider className="block-divider" />
        <CommonInfoBody label="From:">
          <span className="link-warning-hover-body">
            <RedirectionText
              label={overviewDetails?.from?.id}
              goToInnerNavigate={() =>
                navigate(`/address/${overviewDetails?.from?.id}`)
              }
            />
          </span>{" "}
          {overviewDetails?.from?.name && (
            <span className="common-info-text">
              overviewDetails?.from?.name({overviewDetails?.from?.name})
            </span>
          )}{" "}
          <FontAwesomeIcon
            icon={faCopy}
            width={12}
            height={12}
            color="#adb5bd"
            onClick={() => {
              navigator.clipboard.writeText(overviewDetails?.from?.id);
            }}
            style={{ cursor: "pointer" }}
          />
        </CommonInfoBody>
        <CommonInfoBody label="Interacted With (To):">
          <span className="link-warning-hover-body">
            {/* <FontAwesomeIcon
              icon={faFileLines}
              width={12}
              height={12}
              color="#adb5bd"
            />{" "} */}
            <RedirectionText
              label={overviewDetails?.to?.id}
              goToInnerNavigate={() =>
                navigate(`/address/${overviewDetails?.to?.id}`)
              }
            />
          </span>{" "}
          {/* <span className="common-info-text">({overviewDetails?.to?.id})</span>{" "} */}
          <FontAwesomeIcon
            icon={faCopy}
            width={12}
            height={12}
            color="#adb5bd"
            onClick={() => {
              navigator.clipboard.writeText(overviewDetails?.to?.id);
            }}
            style={{ cursor: "pointer" }}
          />{" "}
          {/* <FontAwesomeIcon
            icon={faCircleCheck}
            width={14}
            height={14}
            color={"#00a186"}
          /> */}
        </CommonInfoBody>

        {/* <CommonHorizontalDivider className="block-divider" />
        <CommonInfoBody label="ERC-20 Tokens Transferred:" showFlexStart>
          <div className="common-tab-content nav-overview-tab">
            <Nav pills className="p-0">
              {TAB_LIST &&
                TAB_LIST.length > 0 &&
                TAB_LIST.map((item) => (
                  <NavItem key={item.id}>
                    <NavLink
                      className={`${item.id === activeTab ? "active" : ""}`}
                      onClick={() => toggleTab(item.id)}
                    >
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>
                <span className="d-flex align-items-center gap-1">
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    width={6}
                    height={6}
                    color="#adb5bd"
                  />
                  <div className="transfer-tab">
                    <span className="common-info-text fw-bold">From</span>
                    <span className="link-warning-hover-body px-1">
                      <RedirectionText
                        label="Stake.com"
                        outerNavigateLink="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7?a=0x974caa59e49682cda0ad2bbe82983419a2ecc400"
                      />
                    </span>
                    <span className="common-info-text fw-bold">To </span>
                    <span className="link-warning-hover-body px-1">
                      <img src={DummyImage} alt="" width={15} height={15} />{" "}
                      <RedirectionText
                        label="0x372CA8B6...d37Ab7762"
                        outerNavigateLink="https://etherscan.io/address/0x372ca8b641b37e828ca71bab6983e54d37ab7762"
                      />
                    </span>
                    <span className="common-info-text fw-bold">For </span>
                    <span className="common-info-text">100 </span>
                    <span className="common-secondary-text">($100.10)</span>
                    <span className="link-warning-hover-body px-1">
                      <img src={UsdImage} alt="" width={15} height={15} />{" "}
                      <RedirectionText
                        label="USDT"
                        outerNavigateLink="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7"
                      />
                    </span>
                  </div>
                </span>
              </TabPane>
              <TabPane tabId={2}>
                <RedirectionText
                  label="Stake.com"
                  outerNavigateLink="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7?a=0x974caa59e49682cda0ad2bbe82983419a2ecc400"
                />
                <span className="common-info-text fw-bold"> sent </span>
                <span className="common-secondary-text">100 </span>
                <span className="link-warning-hover-body px-1">
                  <img src={UsdImage} alt="" width={15} height={15} />{" "}
                  <RedirectionText
                    label="Tether USD"
                    outerNavigateLink="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7"
                  />
                  <span className="common-secondary-text"> (USDT) </span>
                </span>
                <div className="mt-2">
                  <span className="link-warning-hover-body ">
                    <img src={DummyImage} alt="" width={15} height={15} />{" "}
                    <RedirectionText
                      label="0x372CA8B6...d37Ab7762"
                      outerNavigateLink="https://etherscan.io/address/0x372ca8b641b37e828ca71bab6983e54d37ab7762"
                    />
                  </span>
                  <span className="common-info-text fw-bold"> received </span>
                  <span className="common-secondary-text">100</span>
                  <span className="link-warning-hover-body px-1">
                    <img src={UsdImage} alt="" width={15} height={15} />{" "}
                    <RedirectionText
                      label="Tether USD"
                      outerNavigateLink="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7"
                    />
                    <span className="common-secondary-text"> (USDT) </span>
                  </span>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </CommonInfoBody> */}
        <CommonHorizontalDivider className="block-divider" />
        <CommonInfoBody label="Value:">
          <span className="d-inline-flex align-items-center gap-1">
            <FontAwesomeIcon
              icon={faEthereum}
              color="#6c757d"
              width={9}
              height={15}
            />
            <span className="common-info-text">
              {overviewDetails?.value?.primary}
            </span>
            <span className="common-secondary-text">
              ({overviewDetails?.isWithdrawUsd ? "$" : ""}
              {overviewDetails?.isWithdrawUsd
                ? parseInt(overviewDetails?.value?.secondary).toFixed(2)
                : overviewDetails?.value?.secondary}
              )
            </span>
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Transaction Fee:">
          <span className="common-info-text">
            {overviewDetails?.transactionFee?.fee}{" "}
          </span>
          <span className="common-info-text info-text-chip other-chip ms-1 cu-line-height-1">
            {overviewDetails?.transactionFee?.subFee}
          </span>
        </CommonInfoBody>
        <CommonInfoBody label="Gas Price:" customClassName="block-info-body">
          <span className="common-info-text">
            {overviewDetails?.gasPrice?.primary}{" "}
          </span>
          <span className="common-secondary-text">
            ({overviewDetails?.gasPrice?.secondary})
          </span>
        </CommonInfoBody>
      </Card>
      <Card className="common-tab-body mb-3">
        {showMoreData && (
          <div className="more-data-body">
            <CommonInfoBody label="Ether Price:">
              <span className="common-info-text">
                ${overviewDetails?.etherPrice} / ETH
              </span>
            </CommonInfoBody>
            <CommonInfoBody label="Gas Limit & Usage by Txn:">
              <span className="common-info-text">
                {overviewDetails?.gasLimit?.primary}
              </span>
              <span className="common-secondary-text mx-2">|</span>
              <span className="common-info-text">
                {overviewDetails?.gasLimit?.secondary}
              </span>
            </CommonInfoBody>
            <CommonInfoBody label="Gas Fees:">
              <div className="d-flex align-items-center flex-wrap">
                {overviewDetails?.gasFees &&
                  overviewDetails?.gasFees.length > 0 &&
                  overviewDetails?.gasFees.map((item, index) => (
                    <div key={index}>
                      <span className="common-secondary-text">
                        {item?.label}
                      </span>
                      <span className="common-info-text"> {item?.value}</span>
                      {index !== overviewDetails?.gasFees.length - 1 && (
                        <span className="common-secondary-text mx-2">|</span>
                      )}
                    </div>
                  ))}
              </div>
            </CommonInfoBody>
            <CommonInfoBody label="Burnt Fees:">
              <div className="d-flex align-items-center gap-1 flex-wrap">
                <div className="common-info-text info-text-chip other-chip ms-1 cu-line-height-1">
                  <span className="chip-secondary-text">🔥 Burnt:</span>
                  <span className="chip-info-text fw-bold">
                    {" "}
                    {overviewDetails?.burnt?.value} (
                    {overviewDetails?.burnt?.subValue})
                  </span>
                </div>
                <div className="common-info-text info-text-chip other-chip ms-1 cu-line-height-1">
                  <span className="chip-secondary-text">💸 Txn Savings:</span>
                  <span className="chip-info-text fw-bold">
                    {" "}
                    {overviewDetails?.savingFee?.value} (
                    {overviewDetails?.savingFee?.subValue})
                  </span>
                </div>
              </div>
            </CommonInfoBody>
            <CommonHorizontalDivider className="block-divider" />
            <CommonInfoBody label="Other Attributes:">
              <span className="common-info-text info-text-chip other-chip cu-line-height-1">
                <span className="chip-secondary-text">Txn Type: </span>
                <span className="chip-info-text fw-bold">
                  {overviewDetails?.other?.txnType}
                </span>
              </span>
              <span className="common-info-text info-text-chip other-chip ms-2 cu-line-height-1">
                <span className="chip-secondary-text">Nonce: </span>
                <span className="chip-info-text fw-bold">
                  {overviewDetails?.other?.nonce}
                </span>
                <span className="chip-info-text">
                  {/* {" "}
                  (Also found 1 Other Dropped Txn #1 with the same `From`
                  Account Nonce) */}
                </span>
              </span>
              <span className="common-info-text info-text-chip other-chip ms-2 cu-line-height-1">
                <span className="chip-secondary-text">Position In Block: </span>
                <span className="chip-info-text fw-bold">
                  {overviewDetails?.other?.positionInBlock}
                </span>
              </span>
            </CommonInfoBody>
            <CommonInfoBody label="Input Data: " showFlexStart>
              <Input
                id="exampleText"
                className="textarea-input"
                name="text"
                type="textarea"
                rows={3}
                disabled
                value={overviewDetails?.inputData}
                // value="
                // Function: transfer(address _to, uint256 _value)

                // MethodID: 0xa9059cbb
                // [0]:  000000000000000000000000372ca8b641b37e828ca71bab6983e54d37ab7762
                // [1]:  0000000000000000000000000000000000000000000000000000000005f5e100
                // "
              />
            </CommonInfoBody>
            <CommonHorizontalDivider className="block-divider" />
          </div>
        )}
        <CommonInfoBody
          label="More Details:"
          customClassName="block-info-body"
          hideInfoIcon
        >
          <span
            className="common-link-text"
            onClick={() => setShowMoreData(!showMoreData)}
          >
            <FontAwesomeIcon
              icon={faPlus}
              width={14}
              height={14}
              color="#0784c3"
            />
            <span className="ps-1">
              Click to show {!showMoreData ? "more" : "less"}
            </span>
          </span>
        </CommonInfoBody>
      </Card>
      <Card className="common-tab-body mb-3">
        <CommonInfoBody label="Private Note:" customClassName="block-info-body">
          <span className="common-info-text">To access the</span>
          <span className="common-info-text fw-bold"> Private Note </span>
          <span className="common-info-text">feature, you must be </span>
          <span className="common-info-text cu-text-primary">Logged In</span>
        </CommonInfoBody>
      </Card>
    </>
  );
};

export default TransactionOverviewTab;
